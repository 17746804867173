import {
  AddCircle as AddCircleIcon,
  DeveloperMode as DeveloperModeIcon,
  WarningRounded as WarningRoundedIcon,
} from "@mui/icons-material";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { ActionProducts, addAction, AppShieldingFeatures } from "../../../../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { CardHeader } from "../../../../../../../_common/ui/card/card-header";
import { FixedWidthSkeleton } from "../../../../../../../_common/ui/skeleton/fixed-width-skeleton";
import { FullWidthSkeleton } from "../../../../../../../_common/ui/skeleton/full-width-skeleton";
import { DebugModeTag } from "../../../../../_common/ui/debug-mode-tag";
import { ConfigStatus } from "../../../../configurations/api/types/config-status";
import { ShielderStatus } from "../../../../shielders/types/shielder-status";
import { GetProject } from "../../../api/get-project";

interface ConfigurationInfoProps {
  project?: GetProject;
  isLoading?: boolean;
}

export function ConfigurationInfo({ project, isLoading }: ConfigurationInfoProps) {
  const t = useTranslate();
  const { classes } = useStyles();
  const noConfiguration = project?.configStatus === ConfigStatus.None;
  const unsupportedShielder = project?.shielderStatus === ShielderStatus.Unsupported;

  return (
    <Card component="section" aria-label={t("component.configuration-info.title")}>
      <CardHeader
        title={
          isLoading ? (
            <FixedWidthSkeleton data-testid="configuration-info-title-skeleton-loader" />
          ) : (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography component="h2" fontSize="1rem" fontWeight="500">
                {t("component.configuration-info.title")}
              </Typography>
              <DebugModeTag show={project?.debugMode ?? false} />
            </Box>
          )
        }
      />
      <CardContent className={classes.cardContent}>
        {isLoading ? (
          <>
            <Box mb={2}>
              <FullWidthSkeleton data-testid="configuration-info-content-line-one-skeleton-loader" />
            </Box>
            <FixedWidthSkeleton data-testid="configuration-info-content-line-two-skeleton-loader" />
          </>
        ) : noConfiguration ? (
          <Box className={classes.noConfigurationContent}>
            <WarningRoundedIcon className={classes.warningIcon} />
            <Typography className={classes.noConfigTitle}>
              {t("component.configuration-info.no-configuration.title")}
            </Typography>
            <Typography className={classes.noConfigMessage}>
              {t("component.configuration-info.no-configuration.message")}
            </Typography>

            <Button
              className={classes.button}
              variant="contained"
              component={Link}
              size="small"
              color="primary"
              to="configuration/new"
              startIcon={<AddCircleIcon />}
              disabled={unsupportedShielder}
              onClick={() => {
                addAction({
                  name: "create-configuration",
                  product: ActionProducts.AppShielding,
                  feature: AppShieldingFeatures.ProjectsDetails,
                });
              }}
            >
              {t("common.create-configuration")}
            </Button>
          </Box>
        ) : (
          <>
            <Typography>{t("component.configuration-info.description")}</Typography>

            <Button
              className={classes.button}
              variant="outlined"
              component={Link}
              size="small"
              color="primary"
              startIcon={<DeveloperModeIcon />}
              to="configuration"
              onClick={() => {
                addAction({
                  name: "view-configuration",
                  product: ActionProducts.AppShielding,
                  feature: AppShieldingFeatures.ProjectsDetails,
                });
              }}
            >
              {t("common.view-configuration")}
            </Button>
          </>
        )}
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles()(theme => ({
  cardContent: {
    padding: theme.spacing(2.5),
    "&:last-child": {
      padding: theme.spacing(2.5),
    },
  },
  noConfigurationContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  noConfigTitle: {
    fontWeight: 500,
    lineHeight: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  noConfigMessage: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: theme.spacing(2.5),
  },
  button: {
    marginTop: theme.spacing(2.5),
  },
  warningIcon: {
    fontSize: theme.typography.pxToRem(30),
    color: theme.palette.grey[600],
  },
}));
