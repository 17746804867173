import { Box, Button, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import { ActionProducts, addAction, SettingsFeatures } from "../../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { AsyncConfirmationDialog } from "../../../../../_common/ui/confirmation-dialog/async-confirmation-dialog";
import { useDeleteClientCredentials } from "../api/delete-client-credentials";

export function DeleteClientCredentials() {
  const t = useTranslate();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const { mutateAsync: deleteClientCredentials } = useDeleteClientCredentials();

  const handleCancel = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  return (
    <>
      <Button
        onClick={() => {
          addAction({
            name: "open-delete-client-credentials",
            product: ActionProducts.Settings,
            feature: SettingsFeatures.ClientCredentials,
          });
          setShowConfirmation(true);
        }}
        variant="contained"
        color="error"
      >
        {t("component.settings.client-credentials.delete-client-credentials.button")}
      </Button>
      <AsyncConfirmationDialog
        onConfirm={() => {
          addAction({
            name: "delete-client-credentials",
            product: ActionProducts.Settings,
            feature: SettingsFeatures.ClientCredentials,
          });
          return deleteClientCredentials();
        }}
        onCancel={handleCancel}
        open={showConfirmation}
        title={t("component.settings.client-credentials.delete-dialog-confirm.title")}
        reverseButtonOrder
        confirmButtonLabel={t("component.settings.client-credentials.delete-dialog-confirm.revoke.button")}
        type="error"
      >
        <Box width={440}>
          <Typography color="textPrimary">
            {t("component.settings.client-credentials.delete-dialog-confirm.message")}
          </Typography>
        </Box>
      </AsyncConfirmationDialog>
    </>
  );
}
