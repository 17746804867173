import { Box, Card } from "@mui/material";

import { ActionProducts, addAction, SettingsFeatures } from "../../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { CardSection } from "../../../../../_common/ui/card/card-section";
import { FormSection } from "../../../../../_common/ui/form-section/form-section";
import { ApiAccessDocumentationButton } from "../../../../app-shielding/features/api-access/ui/api-access-documentation-button";
import { TextForClipboardBlock } from "../../../_common/ui/text-for-clipboard-block";
import { useGetClientCredentials } from "../api/get-client-credentials";
import { CreateClientCredentials } from "./create-client-credentials";
import { DeleteClientCredentials } from "./delete-client-credentials";

export const ClientCredentials = () => {
  const t = useTranslate();

  const { data: credentials, isLoading, isError } = useGetClientCredentials();
  const isCredentialsPresent = Boolean(credentials?.clientId && credentials?.clientSecret);

  return (
    <FormSection
      first
      title={t("component.settings.client-credentials.title")}
      aria-label={t("component.settings.client-credentials.title")}
      description={
        <>
          <p>{t("component.settings.client-credentials.description")}</p>
          <ApiAccessDocumentationButton
            onClick={() => {
              addAction({
                name: "go-to-api-documentation",
                product: ActionProducts.Settings,
                feature: SettingsFeatures.ClientCredentials,
              });
            }}
          />
        </>
      }
      content={
        <>
          <Card>
            <CardSection
              title={t("component.settings.client-credentials.client-id.title")}
              aria-label={t("component.settings.client-credentials.client-id.title")}
              description={
                <TextForClipboardBlock
                  isLoading={isLoading || isError}
                  text={credentials?.clientId || null}
                  onClick={() => {
                    addAction({
                      name: "copy-client-id-to-clipboard",
                      product: ActionProducts.Settings,
                      feature: SettingsFeatures.ClientCredentials,
                    });
                  }}
                />
              }
              borderBottom
            />
            <CardSection
              title={t("component.settings.client-credentials.client-secret.title")}
              aria-label={t("component.settings.client-credentials.client-secret.title")}
              description={
                <TextForClipboardBlock
                  isLoading={isLoading || isError}
                  text={credentials?.clientSecret || null}
                  onClick={() => {
                    addAction({
                      name: "copy-client-secret-to-clipboard",
                      product: ActionProducts.Settings,
                      feature: SettingsFeatures.ClientCredentials,
                    });
                  }}
                />
              }
              borderBottom
            />
          </Card>
          {!isLoading && !isError && (
            <Box mt={2} display="flex" justifyContent="flex-end">
              {isCredentialsPresent ? <DeleteClientCredentials /> : <CreateClientCredentials />}
            </Box>
          )}
        </>
      }
    />
  );
};
