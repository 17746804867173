import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { ActionProducts, addAction, AppShieldingFeatures } from "../../../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../../../_common/i18n/hooks/use-translate.hook";
import { ID } from "../../../../../../_common/types";
import { ApplicationBarIconButtonType } from "../../../../../../_common/ui/application-bar/types";
import { Layout } from "../../../../../../_common/ui/layout/layout";
import { useGetProject } from "../../api/get-project";
import { DeleteProjectDialog } from "../delete-project/delete-project-dialog";
import { ProjectDetailsContent } from "./components/project-details-content";
import { ProjectDetailsInfoPanel } from "./components/project-details-info-panel";

export const ProjectDetailsPage = () => {
  const t = useTranslate();
  const projectId = useParams<"projectId">().projectId!;

  const { isLoading, isFetching, data: project, isError } = useGetProject(projectId);
  const [deleteProject, setDeleteProject] = useState<{ id: ID; projectName: string }>();
  const navigate = useNavigate();

  const navigateBack = () => {
    addAction({
      name: "navigate-back",
      product: ActionProducts.AppShielding,
      feature: AppShieldingFeatures.ProjectsDetails,
    });
    navigate("..");
  };

  return (
    <Layout
      iconButtonType={ApplicationBarIconButtonType.Back}
      iconButtonClickHandler={navigateBack}
      isLoading={isLoading || isError}
      title={project?.projectName}
      contextMenu={{
        "aria-label": project?.projectName
          ? t("component.project-list.table.row-context-options.aria-label", { name: project.projectName })
          : "",
        options: [
          {
            icon: EditIcon,
            onClick: () => {
              addAction({
                name: "open-edit-project",
                product: ActionProducts.AppShielding,
                feature: AppShieldingFeatures.ProjectsDetails,
              });
              navigate("edit");
            },
            label: t("word.edit"),
            value: "edit",
          },
          {
            icon: ContentCopyIcon,
            onClick: () => {
              addAction({
                name: "open-duplicate-project",
                product: ActionProducts.AppShielding,
                feature: AppShieldingFeatures.ProjectsDetails,
              });
              navigate("duplicate");
            },
            label: t("word.duplicate"),
            value: "duplicate",
          },
          {
            red: true,
            icon: DeleteIcon,
            onClick: () => {
              addAction({
                name: "open-delete-project",
                product: ActionProducts.AppShielding,
                feature: AppShieldingFeatures.ProjectsDetails,
              });
              setDeleteProject(project);
            },
            label: t("word.delete"),
            value: "delete",
          },
        ],
      }}
    >
      <ProjectDetailsInfoPanel project={project} isLoading={isLoading || isError} isFetching={isFetching} />
      <ProjectDetailsContent project={project} />
      <DeleteProjectDialog
        projectId={deleteProject?.id}
        projectName={deleteProject?.projectName}
        onClose={() => setDeleteProject(undefined)}
        onSuccess={navigateBack}
      />
      <Outlet />
    </Layout>
  );
};
