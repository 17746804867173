import { Typography } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { makeStyles } from "tss-react/mui";

import { ActionProducts, addAction, AppShieldingFeatures } from "../../../../../../../_common/datadog/add-action";
import { useBytesFormatter } from "../../../../../../../_common/hooks/use-bytes-formatter";
import { useCountdownTimer } from "../../../../../../../_common/hooks/use-countdown-timer";
import { useRerender } from "../../../../../../../_common/hooks/use-rerender";
import { useRerenderWithInterval } from "../../../../../../../_common/hooks/use-rerender-with-interval";
import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { GetShielding } from "../../../api/get-shielding";
import { ApiShieldingStatus } from "../../../api/types";
import { DownloadShieldingButton } from "../../../ui/download-shielding-button/download-shielding-button";
import { isShieldingExpired } from "../../../utils/is-shielding-expired";
import { ShieldingPanelSection } from "./shielding-panel-section";

dayjs.extend(relativeTime);

interface ShieldingDownloadPanelProps {
  className?: string;
  isLoading?: boolean;
  shielding: GetShielding | undefined;
}

const REFRESH_DOWNLOAD_LINK_EXPIRY_INTERVAL = 1000;

export function ShieldingDownloadSection({ className, isLoading, shielding }: ShieldingDownloadPanelProps) {
  const t = useTranslate();
  const bytesFormatter = useBytesFormatter();
  const isExpired = isShieldingExpired(shielding?.shieldingExpiryTime);
  const { classes } = useStyles({ isExpired });
  const isSuccess = shielding && shielding.shieldingStatus === ApiShieldingStatus.Success;
  const rerender = useRerender();
  const countdownTimer = useCountdownTimer({ expiryDate: shielding?.shieldingExpiryTime, onExpiry: rerender });

  useRerenderWithInterval({ enabled: !isExpired, refreshInterval: REFRESH_DOWNLOAD_LINK_EXPIRY_INTERVAL });

  return (
    <ShieldingPanelSection
      isLoading={isLoading || !shielding}
      title={t("word.download")}
      addon={
        isSuccess ? (
          <Typography className={classes.expiryTime}>
            {isExpired
              ? t("word.expired")
              : t("word.expires", {
                  countdownTimer,
                })}
          </Typography>
        ) : undefined
      }
      className={className}
      content={
        isSuccess ? (
          <>
            <DownloadShieldingButton
              disabled={isExpired}
              aria-disabled={isExpired}
              className={classes.downloadLink}
              fileName={shielding.shieldingOutputPackageName!}
              shieldingId={shielding.id}
              onClick={() => {
                addAction({
                  name: "download-shielding",
                  product: ActionProducts.AppShielding,
                  feature: AppShieldingFeatures.ShieldingsDetails,
                });
              }}
            />
            <Typography className={classes.fileSize}>
              ({bytesFormatter(shielding?.shieldingOutputPackageSize ?? 0)})
            </Typography>
          </>
        ) : (
          "--"
        )
      }
    />
  );
}

const useStyles = makeStyles<{ isExpired: boolean }>()((theme, { isExpired }) => ({
  downloadLink: {
    padding: 0,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(-0.325),
    textAlign: "left",
    wordBreak: "break-word",
  },
  fileSize: {
    lineHeight: theme.spacing(2.5),
    display: "inline-block",
    fontSize: theme.typography.pxToRem(12),
  },
  expiryTime: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.spacing(2),
    width: !isExpired ? theme.spacing(14) : undefined,
  },
}));
