import { zodResolver } from "@hookform/resolvers/zod";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { ActionProducts, addAction, AppShieldingFeatures } from "../../../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../../../_common/i18n/hooks/use-translate.hook";
import { FormSelect } from "../../../../../../_common/ui/form-select/form-select";
import { OnSubmitCallback, SideForm } from "../../../../../../_common/ui/side-form/side-form";
import { pick } from "../../../../../../_common/utils/pick";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { useGetShieldersList } from "../../../shielders/api/get-shielders-list-item";
import { ShielderStatus } from "../../../shielders/types/shielder-status";
import { ShielderLabel } from "../../../shielders/ui/shielder-label";
import { useCreateProject } from "../../api/create-project/use-create-project";
import { CreateProjectFormSchema } from "../../project-schemas/create-project-form-schema";
import { EditProjectSummaryFormContent } from "../edit-project-summary/components/edit-project-summary-form-content";

export const NewProjectPanel = () => {
  const t = useTranslate();
  const { isLoading, data: shielders, isError } = useGetShieldersList(ShielderStatus.Latest);
  const defaultValues = useMemo(() => ({ shielderId: "", projectName: "", projectDescription: "" }), []);
  const formProps = useForm<CreateProjectFormSchema>({
    defaultValues,
    resolver: zodResolver(CreateProjectFormSchema),
  });

  const selectShielderOptions = !shielders
    ? []
    : shielders?.map(shielder => ({
        label: (
          <ShielderLabel
            platform={shielder.shielderPlatform}
            version={shielder.shielderVersion}
            status={shielder.shielderStatus}
          />
        ),
        value: shielder.shielderId,
      }));

  const { mutateAsync: createProjectAsync, data: responseData, error } = useCreateProject();

  const onSubmit = useCallback<OnSubmitCallback<CreateProjectFormSchema>>(
    ({ shielderId, projectDescription, projectName }) => {
      const shield = pick(
        shielders!.find(shielder => shielder.shielderId === shielderId)!,
        "shielderId",
        "shielderVersion",
        "shielderPlatform",
        "shielderStatus",
      );

      addAction({
        name: "create-project",
        product: ActionProducts.AppShielding,
        feature: AppShieldingFeatures.CreateProject,
        context: {
          shielderPlatform: shield.shielderPlatform,
          shielderVersion: shield.shielderVersion,
        },
      });

      return createProjectAsync({
        projectName: projectName,
        projectDescription: projectDescription ?? "",
        ...shield,
      });
    },
    [shielders, createProjectAsync],
  );
  const search = useLocation().search;

  const successNavigationPath = "/" + appShieldingRootPath + "/projects" + (responseData ? `/${responseData.id}` : "");

  return (
    <SideForm
      formProps={formProps}
      isLoading={isLoading || isError}
      mutationErrors={error}
      onSubmit={onSubmit}
      submitButtonProps={{ startIcon: <AddCircleIcon /> }}
      cancelNavigationPath={search ? `..${search}` : ".."}
      successNavigationPath={successNavigationPath}
      translationComponentName="new-project"
    >
      <Box mb={2.5}>
        <FormSelect
          isLoading={isLoading || isError}
          skeletonProps={useMemo(() => ({ width: "79px" }), [])}
          label={t("component.new-project.formSelect.platform.label")}
          nothingSelectedLabel={t("component.new-project.formSelect.platform.nothing-selected-label")}
          options={selectShielderOptions}
          id="shield-select"
          fullWidth
          name="shielderId"
          helperText={
            <>
              <Typography fontWeight={700} component="span" lineHeight="24px">
                {t("component.new-project.formSelect.platform.helper-text.main")}
              </Typography>
              &nbsp;
              <Typography component="span" lineHeight="24px">
                {t("component.new-project.formSelect.platform.helper-text.secondary")}
              </Typography>
            </>
          }
        />
      </Box>
      <EditProjectSummaryFormContent loading={isLoading || isError} />
    </SideForm>
  );
};
