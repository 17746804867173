import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { Alert } from "../../../../../_common/ui/alert/alert";
import { RevokeAccessApiKeyDialog } from "../../api-access/components/revoke-access-api-key-dialog";
import { GetProject } from "../../projects/api/get-project";

type UnsupportedShielderAlertProps = {
  project?: GetProject;
  description: string;
  selectVersionPath: string;
  onChange: () => void;
};

export const UnsupportedShielderVersionAlert = ({
  project,
  description,
  selectVersionPath,
  onChange,
}: UnsupportedShielderAlertProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  const navigateToSelectVersion = useCallback(() => navigate(selectVersionPath), [navigate, selectVersionPath]);

  const handleChangeShieldVersion = useCallback(() => {
    onChange();
    return project?.apiKeyId ? setOpenDialog(true) : navigateToSelectVersion();
  }, [navigateToSelectVersion, onChange, project?.apiKeyId]);

  return (
    <>
      <Alert
        type="error"
        title={t("common.shielder-status.alert.unsupported-shield-version.title")}
        description={description}
        primaryButton={{
          label: t("component.basic-info.change-shield-version.button"),
          onClick: handleChangeShieldVersion,
        }}
      />
      <RevokeAccessApiKeyDialog
        open={openDialog}
        project={project}
        onRevoked={() => navigateToSelectVersion()}
        onCancel={() => setOpenDialog(false)}
        title={t("component.revoke-api-key.before-change-shield-version.dialog.title")}
        content={t("component.revoke-api-key.before-change-shield-version.dialog.message")}
      />
    </>
  );
};
