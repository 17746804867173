import { Button } from "@mui/material";

import { ActionProducts, addAction, AppShieldingFeatures } from "../../../../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { ID } from "../../../../../../../_common/types";
import { ButtonProgress } from "../../../../../../../_common/ui/button-progress/button-progress";
import { useDownloadSdk } from "../../../../shielders/api/use-download-sdk";
import { Platform } from "../../../../shielders/types/platform";
import { Version } from "../../../../shielders/types/version";

type DownloadSdkButtonProps = {
  className: string;
  shielder: {
    shielderId: ID;
    shielderPlatform: Platform;
    shielderVersion: Version;
  };
};

export const DownloadSdkButton = ({ className, shielder }: DownloadSdkButtonProps) => {
  const t = useTranslate();

  const { mutateAsync: downloadSDK, isPending: isDownloading } = useDownloadSdk(shielder);

  const handleClick = () => {
    addAction({
      name: "download-shielder-sdk",
      product: ActionProducts.AppShielding,
      feature: AppShieldingFeatures.ProjectsDetails,
    });
    void downloadSDK(shielder.shielderId);
  };

  return (
    <Button
      variant="text"
      color="primary"
      size="small"
      className={className}
      startIcon={isDownloading ? <ButtonProgress role="progressbar" size={12} /> : undefined}
      onClick={!isDownloading ? handleClick : undefined}
    >
      {t("component.basic-info.download-sdk.button")}
    </Button>
  );
};
