import { ExpandMore } from "@mui/icons-material";
import { Select as MuiSelect, SelectProps as MuiSelectProps } from "@mui/material";
import { Children, cloneElement, forwardRef, ReactElement, ReactNode } from "react";

import { useFormFieldContext } from "../form-field/form-field-context";
import { InputStatus } from "../form-input/form-input";
import { TextInput, TextInputProps } from "../text-input/text-input";
import { OptionProps } from "./option";

type SelectChild = ReactElement<OptionProps>;

export interface SelectProps extends MuiSelectProps {
  children: ReactElement<OptionProps>[];
  status?: InputStatus;
  textInputProps?: TextInputProps;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      children,
      disabled,
      displayEmpty,
      fullWidth,
      id: idProp,
      multiple,
      native,
      readOnly,
      textInputProps = {},
      size,
      status,
      inputProps = {},
      ...props
    },
    ref,
  ) => {
    const fieldContext = useFormFieldContext();

    const id = fieldContext?.id || idProp;

    return (
      <MuiSelect
        variant="outlined"
        input={<TextInput fullWidth={fullWidth} multiline={multiple} status={status} {...textInputProps} />}
        disabled={disabled}
        multiple={multiple}
        native={native}
        readOnly={readOnly}
        ref={ref}
        inputProps={{
          size,
          id,
          ...inputProps,
        }}
        IconComponent={ExpandMore}
        displayEmpty={displayEmpty}
        notched={undefined}
        {...props}
      >
        {children
          ? Children.map<ReactNode, ReactNode>(children, child =>
              cloneElement(child as SelectChild, { multiple, native }),
            )
          : null}
      </MuiSelect>
    );
  },
);

Select.displayName = "Select";
