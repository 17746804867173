import { Api as ApiIcon } from "@mui/icons-material";
import { Button } from "@mui/material";

import { ActionProducts, addAction, AppShieldingFeatures } from "../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../_common/i18n/hooks/use-translate.hook";
import { ButtonProgress } from "../../../../_common/ui/button-progress/button-progress";
import { GetProject } from "../projects/api/get-project";
import { ShielderStatus } from "../shielders/types/shielder-status";
import { useCreateAccessApiKey } from "./api/create-access-api-key/use-create-access-api-key";

interface GenerateAccessApiKeyButtonProps {
  project?: GetProject;
  isLoading?: boolean;
}

export const GenerateAccessApiKeyButton = ({ project }: GenerateAccessApiKeyButtonProps) => {
  const t = useTranslate();
  const { mutateAsync: createAccessApiKeyAsync, isPending: isGeneratingApiKey } = useCreateAccessApiKey(project);

  const handleGenerateAccessApiKey = () => {
    addAction({
      name: "generate-configuration-key",
      product: ActionProducts.AppShielding,
      feature: AppShieldingFeatures.ProjectsDetails,
    });
    createAccessApiKeyAsync({ projectId: project!.id, configId: project!.configId }).catch(() => {});
  };

  const isUnsupportedShielder = project?.shielderStatus === ShielderStatus.Unsupported;

  return (
    <Button
      onClick={handleGenerateAccessApiKey}
      disabled={isGeneratingApiKey || isUnsupportedShielder}
      variant="outlined"
      size="small"
      color="primary"
    >
      {isGeneratingApiKey ? <ButtonProgress sx={{ mr: 1 }} /> : <ApiIcon sx={{ mr: 1 }} />}
      {t("component.api-access.generate-api-key.button")}
    </Button>
  );
};
