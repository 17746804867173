import { Download as DownloadIcon } from "@mui/icons-material";
import { alpha } from "@mui/material";
import { tss } from "tss-react/mui";

import { ActionProducts, addAction, OnPremShielderFeatures } from "../../../../_common/datadog/add-action";
import { ButtonProgress } from "../../../../_common/ui/button-progress/button-progress";
import { TextSkeleton } from "../../../../_common/ui/skeleton/text-skeleton";
import { ColumnCellProps } from "../../../../_common/ui/table/data-table";
import { RowButton } from "../../../../_common/ui/table/data-table/row-button";
import { useDownloadOnPremShielder } from "../../api/use-download-on-prem-shielder";

type FileNameCellProps = ColumnCellProps<
  {
    fileName: string;
  },
  unknown
>;
export const FileNameCell = ({ fileName, isLoading }: FileNameCellProps) => {
  const { classes } = useStyles();
  const { mutateAsync: download, isPending: isDownloading } = useDownloadOnPremShielder(fileName);
  return isLoading ? (
    <TextSkeleton height="24px" width="50%" />
  ) : (
    <RowButton
      className={classes.button}
      onClick={
        !isDownloading
          ? () => {
              addAction({
                name: "download-shielder",
                product: ActionProducts.OnPremShielders,
                feature: OnPremShielderFeatures.ShieldersList,
              });
              void download(fileName);
            }
          : undefined
      }
      startIcon={isDownloading ? <ButtonProgress role="progressbar" size={18} /> : <DownloadIcon />}
    >
      {fileName}
    </RowButton>
  );
};

const useStyles = tss.create(({ theme }) => ({
  button: {
    display: "flex",
    width: `calc(100% + ${theme.spacing(5)})`,
    padding: theme.spacing(2.5),
    margin: theme.spacing(-2.5),
    justifyContent: "start",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
    "&:focus-visible": {
      outline: `solid 2px ${theme.palette.primary.main}`,
      transform: "scale(1)",
    },
  },
}));
