import { List, ListItem, ListItemText } from "@mui/material";
import { ReactNode } from "react";
import { useController } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { TextSkeleton } from "../skeleton/text-skeleton";

interface SelectListItem<T extends string | number> {
  label: ReactNode;
  value: T;
}
interface SelectListProps<T extends string | number> {
  name: string;
  items: SelectListItem<T>[];
  selectedItemAddon?: ReactNode;
  unselectedItemAddon?: ReactNode;
  isLoading?: boolean;
  skeletonsCount?: number;
}

const DEFAULT_LOADING_ROW_COUNT = 6;

export function SelectList<T extends string | number>({
  items = [],
  name,
  selectedItemAddon,
  unselectedItemAddon,
  isLoading,
}: SelectListProps<T>) {
  const { classes, cx } = useStyles();
  const { field } = useController({ name });

  return isLoading ? (
    <List>
      {Array.from({ length: DEFAULT_LOADING_ROW_COUNT }).map((_, index) => (
        <ListItem key={index} disablePadding className={classes.loadingItem}>
          <ListItemText>
            <TextSkeleton width="100%" height={48} />
          </ListItemText>
        </ListItem>
      ))}
    </List>
  ) : (
    <List>
      {items.map(item => (
        <ListItem
          button
          onClick={() => field.onChange(item.value)}
          key={item.value}
          secondaryAction={item.value === field.value ? selectedItemAddon : unselectedItemAddon}
          disablePadding
          className={cx(classes.item, { [classes.itemSelected]: item.value === field.value })}
        >
          <ListItemText>{item.label}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

const loadingFadingOut = Object.fromEntries(
  Array.from({ length: DEFAULT_LOADING_ROW_COUNT - 1 }).map((_, i, arr) => [
    `&:nth-of-type(${2 + i})`,
    { opacity: (1.0 / (arr.length + 1)) * (arr.length - i) },
  ]),
);

const useStyles = makeStyles()(theme => ({
  loadingItem: {
    minHeight: theme.spacing(6),
    padding: 0,
    marginBottom: theme.spacing(1),
    "& .MuiListItemText-root": {
      margin: 0,
    },
    ...loadingFadingOut,
  },
  item: {
    minHeight: theme.spacing(6),
    backgroundColor: "#fbfbfb",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5, 2.5),
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
    "& .MuiListItemSecondaryAction-root > p": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      color: theme.palette.grey[600],
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(20),
      "& svg": {
        marginLeft: theme.spacing(1.1),
      },
    },
    "& .MuiListItemText-root": {
      margin: 0,
    },
  },
  itemSelected: {
    backgroundColor: theme.palette.grey[100],
  },
}));
