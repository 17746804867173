import { Logout as LogoutIcon } from "@mui/icons-material";
import { Avatar, ListItemIcon, ListItemText, Menu, MenuItem as MuiMenuItem } from "@mui/material";
import React from "react";

import { useAuthActions } from "../../auth/auth-provider/auth-actions-context";
import { useAuthUserProfile } from "../../auth/auth-provider/auth-user-profile-context";
import { ActionProducts, addAction, GeneralFeatures } from "../../datadog/add-action";
import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { MenuItem } from "./menu-item";

export const UserProfile = ({ sideBarOpen }: UserProfile) => {
  const t = useTranslate();
  const { logout } = useAuthActions();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const { name, company } = useAuthUserProfile();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    addAction({
      name: "open-user-profile-menu",
      product: ActionProducts.All,
      feature: GeneralFeatures.SideBar,
    });

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem
        open={sideBarOpen}
        icon={<Avatar />}
        label={name}
        subLabel={company}
        buttonProps={{ onClick: handleClick }}
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={menuOpen}
        onClose={handleClose}
      >
        <MuiMenuItem
          onClick={() => {
            addAction({
              name: "logout",
              product: ActionProducts.All,
              feature: GeneralFeatures.SideBar,
            });

            return logout();
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>{t("component.sidebar.logout")}</ListItemText>
        </MuiMenuItem>
      </Menu>
    </>
  );
};

type UserProfile = {
  sideBarOpen: boolean;
};
