import { Box, Card, Divider } from "@mui/material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ActionProducts, addAction, AppShieldingFeatures } from "../../../../../../_common/datadog/add-action";
import { useRerender } from "../../../../../../_common/hooks/use-rerender";
import { useTranslate } from "../../../../../../_common/i18n/hooks/use-translate.hook";
import { DataTable, DataTableProps } from "../../../../../../_common/ui/table/data-table";
import { Paginator, useCurrentPage } from "../../../../../../_common/ui/table/paginator";
import { GetProject } from "../../../projects/api/get-project";
import { GetShieldingsListItem, useGetShieldingsList } from "../../api/get-shieldings-list-item";
import { isShieldingExpired } from "../../utils/is-shielding-expired";
import { ShieldingCell } from "./components/cells/shielding-cell";
import { StartedCell } from "./components/cells/started-cell";
import { StatusCell } from "./components/cells/status-cell";
import { VersionCell } from "./components/cells/version-cell";
import { DownloadLinkExpandedRow } from "./components/download-link-expanded-row";
import { ShieldingDropZone } from "./components/shielding-drop-zone";
import { SHIELDINGS_LIST_DEFAULT_SORT_COLUMN, SHIELDINGS_LIST_PAGE_PARAM_NAME } from "./constants";
import { ShieldingsListContext } from "./shieldings-list-context.type";
import { ShieldingsListApiSortColumn } from "./types/shieldings-list-api-sort-column.enum";
import { ShieldingsListUrlSortColumn } from "./types/shieldings-list-url-sort-column.enum";

interface ShieldingsListProps {
  project: GetProject | undefined;
}

export const ShieldingsList = ({ project }: ShieldingsListProps) => {
  const page = useCurrentPage() || 1;
  const { isError, data: { shieldings, totalPages = 0 } = {} } = useGetShieldingsList({ project, page });
  const isLoading = !project || !shieldings;

  const t = useTranslate();
  const navigate = useNavigate();
  const columns = useGenerateColumns();
  const rerender = useRerender();

  const shieldingsWithExpandedRow = shieldings?.map(shielding => ({
    ...shielding,
    expandedRow: !isShieldingExpired(shielding?.shieldingExpiryTime) && shielding.shieldingOutputPackageName && (
      <DownloadLinkExpandedRow shielding={shielding} onExpiry={rerender} />
    ),
  }));

  const showPaginator = project && totalPages > 1;

  return (
    <Box flex="1 1" display="flex" flexDirection="column">
      <Box flex="1 1">
        <Card sx={showPaginator ? { overflow: "visible" } : { overflow: "visible", mb: 2 }}>
          <ShieldingDropZone
            project={project}
            isDisabled={isError}
            isLoading={isLoading}
            showEmptyState={!shieldings?.length}
          />
          <Divider />
          {Boolean(!shieldings || shieldings.length) && (
            <DataTable
              context={project}
              caption={t("component.project-list.table.caption")}
              onClickRow={item => {
                addAction({
                  name: "view-shielding-details",
                  product: ActionProducts.AppShielding,
                  feature: AppShieldingFeatures.ShieldingsList,
                });
                navigate(`shieldings/${item.id}`);
              }}
              isLoading={isLoading || isError}
              isHeadersLoading={isLoading || isError}
              columns={columns}
              defaultSortColumn={SHIELDINGS_LIST_DEFAULT_SORT_COLUMN}
              data={shieldingsWithExpandedRow}
              noPaperContainer
            />
          )}
        </Card>
      </Box>
      {showPaginator && <Paginator total={totalPages} paramName={SHIELDINGS_LIST_PAGE_PARAM_NAME} />}
    </Box>
  );
};

const useGenerateColumns = (): DataTableProps<
  ShieldingsListApiSortColumn,
  ShieldingsListUrlSortColumn,
  Readonly<GetShieldingsListItem>,
  ShieldingsListContext
>["columns"] => {
  const t = useTranslate();
  return useMemo(
    () => [
      {
        width: "100%",
        key: "shielding",
        title: t("component.shieldings-list.column.shielding"),
        component: ShieldingCell,
      },
      {
        width: 210,
        key: "version",
        title: t("component.shieldings-list.column.version"),
        component: VersionCell,
      },
      {
        width: 200,
        key: "started",
        title: t("component.shieldings-list.column.started"),
        component: StartedCell,
      },
      {
        width: 200,
        key: "status",
        title: t("component.shieldings-list.column.status"),
        component: StatusCell,
      },
    ],
    [t],
  );
};
