import { Button } from "@mui/material";

import { ActionProducts, addAction, SettingsFeatures } from "../../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { ButtonProgress } from "../../../../../_common/ui/button-progress/button-progress";
import { useCreateClientCredentials } from "../api/create-client-credentials";

export function CreateClientCredentials() {
  const t = useTranslate();

  const { mutate: createClientCredentials, isPending: isCreating } = useCreateClientCredentials();

  return (
    <Button
      startIcon={isCreating ? <ButtonProgress /> : undefined}
      disabled={isCreating}
      onClick={() => {
        addAction({
          name: "create-client-credentials",
          product: ActionProducts.Settings,
          feature: SettingsFeatures.ClientCredentials,
        });
        createClientCredentials();
      }}
      variant="contained"
    >
      {t("component.settings.client-credentials.create-client-credentials.button")}
    </Button>
  );
}
