import { appShieldingRootPath } from "../../../products/app-shielding/app-shielding-root-path";
import { AccessApiKeyI18nToastKey } from "../../../products/app-shielding/features/api-access/api/_support/access-api-key-i18n-toast-key";
import { ConfigurationI18nToastKey } from "../../../products/app-shielding/features/configurations/api/_support/configuration-i18n-toast-key";
import { PageType } from "../../../products/app-shielding/features/configurations/page-type";
import { ProjectI18nToastKey } from "../../../products/app-shielding/features/projects/api/_support/project-i18n-toast-key";
import { ProjectsListUrlSortColumn } from "../../../products/app-shielding/features/projects/features/projects-list/types/projects-list-url-sort-column.enum";
import { ShielderI18nToastKey } from "../../../products/app-shielding/features/shielders/api/_support/shielder-i18n-toast-key";
import { Platform } from "../../../products/app-shielding/features/shielders/types/platform";
import { ShielderStatus } from "../../../products/app-shielding/features/shielders/types/shielder-status";
import { ShieldingI18nToastKey } from "../../../products/app-shielding/features/shieldings/api/_support/shielding-i18n-toast-key";
import { ApiShieldingStatus } from "../../../products/app-shielding/features/shieldings/api/types";
import { ShieldingStatus } from "../../../products/app-shielding/features/shieldings/types";
import { onPremShieldersRootPath } from "../../../products/on-prem-shielders/on-prem-shielders-root-path";
import { ClientCredentialsI18nToastKey } from "../../../products/settings/features/client-credentials/api/_support/client-credentials-i18n-toast-key";
import { settingsRootPath } from "../../../products/settings/settings-root-path";
import { AuthState } from "../../auth/auth-provider/auth-state";
import { SizeUnit } from "../../types";
import { ApiCallResult, ApiCallType, getApiCallToastI18nKey } from "../utils/get-api-call-toast-i18n-key";

/* eslint-disable sonarjs/no-duplicate-string */
// eslint-disable-next-line import/no-unused-modules
export type Languages = "en" | "fr";
export type LanguageKeys = keyof typeof en;
export type Translations = Record<LanguageKeys, string>;

const en: Record<string, string> = {
  "actions.see-more": "See more",
  "actions.see-less": "See less",
  "common.create-configuration": "Create configuration",
  "common.save-configuration": "Save configuration",
  "common.edit-configuration": "Edit configuration",
  "common.view-configuration": "View configuration",
  "common.select-file": "Select file",
  "common.project.debug-mode": "Debug mode",
  "common.configuration.debug-mode.switch.aria-label": "Debug mode enables options for testing",
  "common.configuration.debug-mode.switch": "Debug mode",
  "common.configuration.debug-mode.switch.tooltip":
    "Specify whether the shielding must be done in test mode. Several security features are disabled in test mode to ease the testing of the app during the development. This option must be disabled for a production app.",
  "common.configuration.debug-mode.icon.tooltip":
    "This parameter can be configured in “Debug” mode for troubleshooting and test purposes.",
  "common.configuration.debug-mode.tag": "Debug mode",
  "common.configuration.debug-mode.alert.title": "Debug mode is enabled",
  "common.configuration.debug-mode.alert.description":
    'There are a number of parameters that are available when configuration is set to "Debug mode". These parameters can be used for troubleshooting and testing.\n' +
    "Several security features are disabled in test mode to ease the testing of the app during the development. This option must be disabled for a production app.",
  [`common.configuration.debug-mode.${true.toString()}.parameter.warning`]:
    "Parameter can be disabled for troubleshooting and debug purpose",
  [`common.configuration.debug-mode.${false.toString()}.parameter.warning`]:
    "Parameter required for production. Can only be changed in “Debug” mode ",
  "common.configuration.new-parameters.alert": "The App Shielding version { version } includes new parameters.",
  "common.shielder-status.alert.unsupported-shield-version.title": "Unsupported App Shielding version",
  "common.shielder-status.alert.unsupported-shield-version.no-configuration.description":
    "Please update the App Shielding version to create configuration.",
  "common.shielder-status.alert.unsupported-shield-version.with-configuration.description":
    "Please update the App Shielding version to trigger a new shielding.",
  "common.shielder-status.alert.unsupported-shield-version.configuration-edit.description":
    "This configuration is for an unsupported App Shielding version. Please update the App Shielding version to edit configuration.",
  [`common.size.unit.${SizeUnit.B}`]: "{ value } B",
  [`common.size.unit.${SizeUnit.KB}`]: "{ value } KB",
  [`common.size.unit.${SizeUnit.MB}`]: "{ value } MB",
  [`common.size.unit.${SizeUnit.GB}`]: "{ value } GB",
  [`common.size.unit.${SizeUnit.TB}`]: "{ value } TB",
  [`common.size.unit.${SizeUnit.PB}`]: "{ value } PB",
  [`common.size.unit.${SizeUnit.EB}`]: "{ value } EB",
  [`common.size.unit.${SizeUnit.ZB}`]: "{ value } ZB",
  [`common.size.unit.${SizeUnit.YB}`]: "{ value } YB",
  "component.on-prem-shielders.file": 'File "{ fileName }"',
  "component.on-prem-shielders.layout.content.title": "App Shielding Tool",
  "component.on-prem-shielders.list.table.caption": "App Shielding",
  "component.on-prem-shielders.list.column.app-shielding": "App Shielding",
  "component.on-prem-shielders.list.empty-state.title": "No App Shieldings",
  "component.on-prem-shielders.list.empty-state.description": "There are no App Shieldings available for download.",
  "component.auth.authenticating.message": "Authenticating...",
  "component.auth.authorizing.message": "Authorizing...",
  [`component.auth.${AuthState.RedirectingToLoginPage}.message`]: "Redirecting to login...",
  [`component.auth.${AuthState.RedirectingToLogoutPage}.message`]: "Logging out...",
  "component.basic-info.title": "About",
  "component.basic-info.change-shield-version.button": "Change version",
  "component.basic-info.download-sdk.button": "Download SDK",
  "component.configuration-info.title": "Configuration",
  "component.configuration-info.no-configuration.title": "No Configuration",
  "component.configuration-info.description": "Manage a configuration parameters to shield your mobile application",
  "component.configuration-info.no-configuration.message": "Get started by defining configuration parameters.",
  "component.configuration-item.file.size": "{ size } bytes",
  "component.configuration-item.drag-and-drop.message": "Drag and drop a file here or {label}",
  "component.configuration-item.drag-and-drop.hover-message": "Drag and drop a file here",
  "component.configuration-item.drag-and-drop.label-message": "browse",
  "component.configuration-item.file.certificate.subject": "Subject",
  "component.configuration-item.file.certificate.issuer": "Issuer",
  "component.configuration-item.file.certificate.from": "From",
  "component.configuration-item.file.certificate.to": "Till",
  "component.configuration-item.option.aria-label": "{ option }, option for { name }",
  "component.configuration-item.help-button.aria-label": "Description for { name }",
  "component.configuration-item.certificate.delete-file.aria-label": "Delete file { name }",
  [`component.configuration.validation-alert.${PageType.New}.message`]:
    "Unable to create configuration. Please resolve the following { count, plural, one {error} other {# errors} } and try again.",
  [`component.configuration.validation-alert.${PageType.Edit}.message`]:
    "Unable to save configuration. Please resolve the following { count, plural, one {error} other {# errors} } and try again.",
  [`component.configuration.validation-alert.jump-to-error.button`]: "Jump to the error",
  [`component.configuration.validation-alert.previous-error.button`]: "Previous error",
  [`component.configuration.validation-alert.next-error.button`]: "Next error",
  "component.api-access.title": "Configuration Key",
  "component.api-access.description":
    "Include app shielding in your mobile app development workflow by using our API and SDK. This Configuration Key will be required to perform Shieldings through Shielding APIs.",
  "component.api-access.copy-button": "Copy to clipboard",
  "component.api-access.copied-button": "Copied!",
  "component.api-access.generate-api-key.button": "Generate Key",
  "component.api-access.revoke-api-key.button": "Revoke Key",
  "component.api-access.revoke-dialog-confirm.title": "Revoke Configuration Key",
  "component.api-access.revoke-dialog-confirm.message":
    "Are you sure you want to revoke the Configuration Key for this project?",
  "component.api-access.revoke-dialog-confirm.revoke-button": "Revoke Key",
  "component.api-access.alert.invalid-key": "Invalid key. Please revoke and re-generate it.",
  "component.api-documentation.button": "API documentation",
  [getApiCallToastI18nKey(ApiCallType.Mutation, ApiCallResult.Success, AccessApiKeyI18nToastKey.CREATE_API_KEY)]:
    "Configuration Key generated",
  [getApiCallToastI18nKey(ApiCallType.Mutation, ApiCallResult.Success, AccessApiKeyI18nToastKey.DELETE_API_KEY)]:
    "Configuration Key revoked",
  "component.base-configuration-form.project-title": "",
  "component.base-configuration-form.section.project-title": "Project and App Shielding",
  "component.base-configuration-form.section.project-description": "summary explanation",
  "component.no-content.configurations.empty-state.description":
    "Start shielding mobile apps by creating a configuration for this project",
  "component.no-content.shields.empty-state.description": "Start shielding mobile apps by selecting a file",
  "component.no-content.shields.empty-state.title": "No shieldings",
  "component.configurations.empty-state.button.label": "Create Configuration",
  "component.configuration-form.item.certificate.certificate-details.aria-label": "Certificate details",
  "component.configuration-form.item.certificate.new-certificate.aria-label": "New Certificate",
  "component.configuration-form.item.validation.each-line.max-length.helperText": "Each line maximum length { value }",
  "component.configuration-form.item.validation.each-line.max-length.message":
    "Maximum character limit exceeded on line(s) { line }",
  "component.configuration-form.item.validation.max-length.message": "Maximum character limit exceeded",
  "component.configuration-form.item.validation.max-lines-count.helperText": "Max { value } lines",
  "component.configuration-form.item.validation.max-lines-count.message": "Max { value } lines",
  "component.configuration-form.item.validation.max-value.helperText": "Max { value }",
  "component.configuration-form.item.validation.max-value.message": "Max { value }",
  "component.configuration-form.item.validation.min-value.helperText": "Min { value }",
  "component.configuration-form.item.validation.min-value.message": "Min { value }",
  "component.configuration-form.item.validation.required.message": "{ label } is missing",
  "component.create-configuration-dialog.debug-mode.title": `Create configuration in "Debug mode"`,
  "component.create-configuration-dialog.debug-mode.text": `The application cannot be released to the market once it is shielded with the configuration in "Debug mode".`,
  "component.edit-configuration-dialog.api-key-present.title": `Revoke configuration KEY to edit the configuration `,
  "component.edit-configuration-dialog.api-key-present.text": `In order to edit the configuration form, first the Configuration Key must be revoked for this project. Once revoked, you’ll need to generate a new Configuration Key to use this project’s SDK.`,
  "component.delete-project-dialog.title": "Delete project?",
  "component.delete-project-dialog.description1": "Are you sure you want to delete  “{ name }”?",
  "component.delete-project-dialog.description2":
    "Deleting a project will destroy it and remove it from the list of projects. This project will be irretrievable and this action cannot be undone.",
  "component.edit-project-summary.alert.description": "Unable to update project, please resolve errors in this form.",
  "component.edit-project-summary.panel.heading": "Edit project",
  "component.edit-project-summary.form.submit": "Save",
  "component.layout.cookie-consent":
    "This website uses cookies to improve user experience, functionality and performance. If you continue browsing the site, you consent to the use of cookies on this website. Note that you can change your browser/cookie settings at any time. To learn more about the use of cookies and how to adjust your settings, please <link>read our cookie policy</link>.",
  "component.layout.cookie-consent.accept-button": "Accept all",
  "component.layout.cookie-consent.reject-button": "Reject all",
  "component.new-project.alert.description": "Unable to create project, please resolve errors in this form.",
  "component.new-project.panel.heading": "Create project",
  "component.new-project.form.submit": "Create",
  "component.project.formInput.name.label": "Name",
  "component.project.formInput.description.label": "Description (optional)",
  "component.shielder-list.form.submit": "Continue",
  "component.shielder-list.panel.heading": "Change App Shielding version",
  "component.shielder-list.alert.description":
    "Different versions may have different configuration features. Updating the version may affect the current configuration",
  "component.new-project.formSelect.platform.label": "Platform",
  "component.new-project.formSelect.platform.helper-text.main": "Start with the most updated App Shielding version.",
  "component.new-project.formSelect.platform.nothing-selected-label": "Select a platform",
  "component.new-project.formSelect.platform.helper-text.secondary":
    "You can change a project’s App Shielding version afterwards if necessary.",
  "component.paginator.page-index": "{ current } of { total }",
  "component.paginator.page-index.aria-label": "Page { current } of { total }",
  "component.paginator.first-page.aria-label": "First page",
  "component.paginator.last-page.aria-label": "Last page",
  "component.paginator.next-page.aria-label": "Next page",
  "component.paginator.prev-page.aria-label": "Previous page",
  "component.projects.layout.content.title": "App Shielding",
  "component.project-list.column.created": "Created",
  "component.project-list.column.project": "Project",
  "component.project-list.column.last-updated": "Last updated",
  "component.project-list.column.shield-version": "App Shielding version",
  "component.project-list.empty-state.button.label": "Create a project",
  "component.project-list.empty-state.description": "Start shielding mobile apps by creating a project.",
  "component.project-list.empty-state.title": "No projects",
  "component.project-list-context-menu-cell.delete-project.label": "Delete",
  "component.project-list.table.caption":
    "App Shielding projects. The columns are sortable with the buttons on the first row.",
  [`component.project-list.table.column-aria-label.${ProjectsListUrlSortColumn.ProjectName}`]:
    "Sort the list by project name",
  [`component.project-list.table.column-aria-label.${ProjectsListUrlSortColumn.CreatedAt}`]:
    "Sort the list by creation date",
  [`component.project-list.table.column-aria-label.${ProjectsListUrlSortColumn.UpdatedAt}`]:
    "Sort the list by last updated date",
  "component.project-list.table.column-aria-label.no-configuration-warning": "Optional message",
  "component.project-list.table.column-aria-label.context": "Actions",
  "component.project-list.table.row-context-options.aria-label": "{ name } project options",
  "component.project-list.table.row-context-options.menu.aria-label": "Actions for { name }",
  "component.view-configuration.page-title": "{ project } configuration",
  "component.edit-configuration.page-title": "Edit configuration",
  "component.new-configuration.page-title": "Create configuration",
  "component.no-configuration.warning": "No configuration",
  "component.duplicate-project.panel.heading": "Duplicate project",
  "component.duplicate-project.form.submit": "Duplicate",
  "component.duplicate-project.alert.description": "Unable to duplicate project, please resolve errors in this form.",
  "component.change-shield.dialog.title": "Unsupported App Shielding version",
  "component.change-shield.dialog.update-version-button": "Update version",
  "component.change-shield.dialog.message":
    "You cannot create a configuration for this project because its App Shielding version is no longer supported. Please update this project's App Shielding version in order to continue.",
  "component.revoke-api-key.before-change-shield-version.dialog.title":
    "Revoke Configuration Key to change App Shielding version",
  "component.revoke-api-key.before-change-shield-version.dialog.message":
    "To change the App Shielding version, the Configuration Key must first be revoked. Once revoked, you'll need to generate a new Configuration Key to use this project's SDK",
  "component.configuration-page.left-button.aria-label": "Go back",
  "component.configuration-page.secondary-button.aria-label": "Cancel and discard unsaved changes",
  "component.configuration-block-description.see-more-about": "See more about {sectionTitle}",
  "component.configuration-block-description.see-less-about": "See less about {sectionTitle}",
  "component.shielding-drop-zone.drag-and-drop.no-configuration.message":
    "Start shielding mobile once your configuration is set",
  "component.shielding-drop-zone.drag-and-drop.message": "{label} or drop a file here to trigger a new shielding",
  "component.shielding-drop-zone.drag-and-drop.label-message": "Select",
  "component.shielding-drop-zone.drag-and-drop.on-drop-message": "Drop file to trigger new shielding",
  "component.shielding-drop-zone.drag-and-drop.title": "Add file to shield",
  "component.file-drop-zone.file-upload-failed": "File upload failed",
  "component.file-drop-zone.length-error-message": "Maximum file name length is {count} characters",
  "component.file-drop-zone.size-error-message": "Maximum file size is {size}{unit}",
  [`component.shielding.status.${ShieldingStatus.InProgress}`]: "In progress...{progress, plural, =-1 {} other {#%}}",
  [`component.shielding.status.${ShieldingStatus.Success}`]: "Completed",
  [`component.shielding.status.${ShieldingStatus.Failed}`]: "Failed",
  [`component.shielding.error-alert.${ApiShieldingStatus.Failed}.title`]: "Shielding failed",
  [`component.shielding.error-alert.${ApiShieldingStatus.Timeout}.title`]: "Shielding timeout",
  [`component.shielding.error-alert.${ApiShieldingStatus.Aborted}.title`]: "Shielding aborted",
  [`component.shielding.error-alert.${ApiShieldingStatus.Success}.title`]: "Shielding completed",
  [`component.shielding.error-alert.description`]: "Please refer to the log for more information or contact support.",
  "component.shieldings-list.column.shielding": "Shielding",
  "component.shieldings-list.column.version": "Version",
  "component.shieldings-list.column.started": "Started",
  "component.shieldings-list.column.status": "Status",
  "component.shieldings-list.download-package": "{ downloadLink } ({ size }) expires in { countdownTimer }",
  [`component.shielder.${Platform.Android}`]: "App Shielding v{ version } for Android",
  [`component.shielder.${Platform.Ios}`]: "App Shielding v{ version } for iOS",
  "component.sidebar.home": "Home",
  "component.sidebar.app-shielding": "App Shielding",
  "component.sidebar.app-shielding-tool": "App Shielding Tool",
  "component.sidebar.settings": "Settings",
  "component.sidebar.userProfile.unknown": "Me",
  "component.sidebar.logout": "Logout",
  "component.drop-zone.accepts": "Accepts {extensions}",
  "component.drop-zone.invalid-file-type": "Invalid file type",
  "component.drop-zone.navigate-away": "Navigating away may interrupt the current upload.\nWould you like to proceed?",
  "component.empty-state.resource-not-found.title": `The "{ resourceName }" no longer exists`,
  "component.empty-state.resource-not-found.description": "The resource you are trying to access no longer exists.",
  "component.empty-state.not-found.title": "Something’s missing.",
  "component.empty-state.not-found.description": "Please check the URL again or let us take you back to the main page.",
  [`component.empty-state.not-found.button.${appShieldingRootPath}`]: "Return to projects",
  [`component.empty-state.not-found.button.${onPremShieldersRootPath}`]: "Return to download list",
  [`component.empty-state.not-found.button.${settingsRootPath}`]: "Return to settings",
  "component.empty-state.not-found.button": "Return to main page",
  "component.empty-state.error-boundary.title": "Something unexpected happened",
  "component.empty-state.error-boundary.description":
    "There was an error while loading this page. If it persists please <supportLink>get in touch</supportLink>.",
  [`component.empty-state.error-boundary.description.${appShieldingRootPath}`]:
    "There was an error while loading this page. If it persists please <supportLink>get in touch</supportLink> or <homePageLink>return to projects</homePageLink>.",
  [`component.empty-state.error-boundary.description.${onPremShieldersRootPath}`]:
    "There was an error while loading this page. If it persists please <supportLink>get in touch</supportLink> or <homePageLink>return to download list</homePageLink>.",
  [`component.empty-state.error-boundary.description.${settingsRootPath}`]:
    "There was an error while loading this page. If it persists please <supportLink>get in touch</supportLink> or <homePageLink>return to settings</homePageLink>.",
  "component.empty-state.error-boundary.reload-button": "Reload this page",
  "component.sessionExpiration.title": "Your session has expired",
  "component.sessionExpiration.message": "Please try connecting again.",
  "component.sessionExpiration.loginButton": "Log in",
  "component.settings.layout.content.title": "Settings",
  "component.settings.client-credentials.title": "Client Credentials",
  "component.settings.client-credentials.description":
    "Include App Shielding in your mobile app development workflow by using our API and SDK. The Client ID and Client Secret will be required to perform Shieldings through Shielding APIs.",
  "component.settings.client-credentials.client-id.title": "Client ID",
  "component.settings.client-credentials.client-secret.title": "Client Secret",
  "component.settings.client-credentials.create-client-credentials.button": "Create Client credentials",
  "component.settings.client-credentials.delete-client-credentials.button": "Delete Client credentials",
  "component.settings.client-credentials.delete-dialog-confirm.title": "Delete Client credentials",
  "component.settings.client-credentials.delete-dialog-confirm.message":
    "Are you sure you want to delete the Client ID and Client Secret for this project?",
  "component.settings.client-credentials.delete-dialog-confirm.revoke.button": "Delete Client credentials",
  [`enum.platform.aria-label.version.${Platform.Android}`]: "Android version { version }",
  [`enum.platform.aria-label.version.${Platform.Ios}`]: "iOS version { version }",
  [`enum.platform.${Platform.Android}`]: "Android",
  [`enum.platform.${Platform.Ios}`]: "iOS",
  [`enum.shielder-status.${ShielderStatus.Latest}`]: "Latest",
  [`enum.shielder-status.${ShielderStatus.Outdated}`]: "Outdated",
  [`enum.shielder-status.${ShielderStatus.Unsupported}`]: "Unsupported",
  [`enum.shielder-status.aria-label.${ShielderStatus.Outdated}`]: "Attention Outdated",
  [`enum.shielder-status.aria-label.${ShielderStatus.Unsupported}`]: "Attention Unsupported",
  // Normally all server validation errors should be represented in translation files, but:
  //   1. Such translation files should be provided from the server as well which is not the case since platform is not
  //      yet ready with translation service
  //   2. BE team changes codes without communication and consultation with FE team so there is no way to track changes
  //      and to be in sync
  // Therefore, we comment them temporarily, and we will show text messages that come from BE - in any case there is
  // only English version for the moment.
  // We keep this comment for the future as the idea how it could be implemented
  // [`serverError.${ServerErrorCode.ProjectNameAlreadyUsed}`]: "This name has been already taken",
  // [`serverError.${ServerErrorCode.InvalidNameProvided}`]: "Invalid name provided",
  // [`serverError.${ServerErrorCode.TooLongProjectName}`]:
  //   "The full name must be less than 50 characters.",
  // [`serverError.${ServerErrorCode.TooLongDescription}`]:
  //   "The full description must be less than 160 characters.",
  // [`serverError.${ServerErrorCode.AccessApiKeyAlreadyExists}`]:
  //   "API key has already been generated for this project.",
  "toast.mutation.error.validation": "Validation error!",
  [getApiCallToastI18nKey(ApiCallType.Mutation, ApiCallResult.Success, ProjectI18nToastKey.UPDATE_PROJECT)]:
    "Project summary updated",
  [getApiCallToastI18nKey(ApiCallType.Mutation, ApiCallResult.Success, ProjectI18nToastKey.DELETE_PROJECT)]:
    "Project deleted",
  [getApiCallToastI18nKey(ApiCallType.Mutation, ApiCallResult.Success, ProjectI18nToastKey.CREATE_PROJECT)]:
    "Project created",
  [getApiCallToastI18nKey(ApiCallType.Mutation, ApiCallResult.Success, ProjectI18nToastKey.UPDATE_SHIELD_VERSION)]:
    "App Shielding version updated",
  [getApiCallToastI18nKey(ApiCallType.Mutation, ApiCallResult.UnknownError)]: "Something went wrong",
  [getApiCallToastI18nKey(ApiCallType.Mutation, 401)]: "Not authenticated",
  [getApiCallToastI18nKey(ApiCallType.Mutation, 403)]: "No access to this resource",
  [getApiCallToastI18nKey(ApiCallType.Mutation, 409)]: "Resource changed cannot apply this update",
  [getApiCallToastI18nKey(ApiCallType.Mutation, 422)]: "Validation error!",
  [getApiCallToastI18nKey(ApiCallType.Mutation, ApiCallResult.Success, ConfigurationI18nToastKey.CREATE_CONFIG)]:
    "Configuration created",
  [getApiCallToastI18nKey(ApiCallType.Mutation, ApiCallResult.Success, ConfigurationI18nToastKey.UPDATE_CONFIG)]:
    "Configuration updated",
  [getApiCallToastI18nKey(ApiCallType.Mutation, ApiCallResult.SuccessMessage, ProjectI18nToastKey.DUPLICATE_PROJECT)]:
    "Project duplicated",
  [getApiCallToastI18nKey(ApiCallType.Mutation, ApiCallResult.SuccessLink, ProjectI18nToastKey.DUPLICATE_PROJECT)]:
    "View project",
  [getApiCallToastI18nKey(ApiCallType.Mutation, 422, ShielderI18nToastKey.DOWNLOAD_SDK)]: "Something went wrong",
  [getApiCallToastI18nKey(ApiCallType.Mutation, 422, ShieldingI18nToastKey.DOWNLOAD_SHIELDING)]: "Something went wrong",
  // [`toast.query.${GENERIC_I18N_KEY}.401`]: "Not authenticated",
  // [`toast.query.${GENERIC_I18N_KEY}.403`]: "No access to this resource",
  [getApiCallToastI18nKey(ApiCallType.Query, 404, ClientCredentialsI18nToastKey.GET)]: "Something went wrong",
  [getApiCallToastI18nKey(ApiCallType.Mutation, 404, ConfigurationI18nToastKey.CREATE_CONFIG)]: "Project was not found",
  [getApiCallToastI18nKey(ApiCallType.Mutation, 404, ConfigurationI18nToastKey.UPDATE_CONFIG)]:
    "Project/configuration was not found",
  [getApiCallToastI18nKey(ApiCallType.Query, 404, ProjectI18nToastKey.GET_PROJECT)]: "The project was not found",
  [getApiCallToastI18nKey(ApiCallType.Query, 404, ProjectI18nToastKey.GET_PROJECTS_LIST)]: "Something went wrong",
  [getApiCallToastI18nKey(ApiCallType.Query, 404, ShielderI18nToastKey.GET_SHIELDERS_LIST)]: "Something went wrong",
  [getApiCallToastI18nKey(ApiCallType.Query, 404, ShielderI18nToastKey.GET_SHIELDER)]: "The shielder was not found",
  [getApiCallToastI18nKey(ApiCallType.Query, 404, ConfigurationI18nToastKey.GET_CONFIG)]:
    "The configuration was not found",
  [getApiCallToastI18nKey(ApiCallType.Query, 404)]: "Resource was not found",
  [getApiCallToastI18nKey(ApiCallType.Query, ApiCallResult.UnknownError)]: "Something went wrong",
  "toast.close-button": "Close the toast",
  "validation.max-length": "This field has to be maximum { maxLength } characters length.",
  "validation.must-not-contain-only-whitespace": "Must not contain only spaces",
  "validation.required": "This field cannot be blank.",
  "word.onespan": "OneSpan",
  "word.of": "of",
  "word.close": "Close",
  "word.cancel": "Cancel",
  "word.collapse": "Collapse",
  "word.create": "Create",
  "word.created": "Created",
  "word.delete": "Delete",
  "word.deprecated": "Deprecated",
  "word.description": "Description",
  "word.duplicate": "Duplicate",
  "word.edit": "Edit",
  "word.expand": "Expand",
  "word.not-available.short": "N/A",
  "word.name": "Name",
  "word.new": "New",
  "word.open": "Open",
  "word.required": "Required",
  "word.retry": "Retry",
  "word.retrying": "Retrying",
  "word.save": "Save",
  "word.select": "Select",
  "word.selected": "Selected",
  "word.shield": "App Shielding",
  "word.update": "Update",
  "word.updated": "Updated",
  "word.version": "Version",
  "word.shielded": "Shielded",
  "word.download": "Download",
  "word.api": "API",
  "word.expires": "Expires { countdownTimer }",
  "word.expired": "Expired",
  "word.uploading": "Uploading",
};

/* eslint-enable sonarjs/no-duplicate-string */
// eslint-disable-next-line import/no-default-export
export default en;
