import { zodResolver } from "@hookform/resolvers/zod";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import { queryKeys } from "../../../../../../_common/api/query-keys/query-keys";
import { ActionProducts, addAction, AppShieldingFeatures } from "../../../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../../../_common/i18n/hooks/use-translate.hook";
import { FormSelect } from "../../../../../../_common/ui/form-select/form-select";
import { OnSubmitCallback, SideForm } from "../../../../../../_common/ui/side-form/side-form";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { ShielderLabel } from "../../../shielders/ui/shielder-label";
import { useDuplicateProject } from "../../api/duplicate-project/use-duplicate-project";
import { useGetProject } from "../../api/get-project";
import { DuplicateProjectFormSchema } from "../../project-schemas/duplicate-project-form-schema";
import { MAX_PROJECT_NAME_LENGTH } from "../../project-schemas/edit-project-summary-form-schema";
import { EditProjectSummaryFormContent } from "../edit-project-summary/components/edit-project-summary-form-content";

function addCopySuffixToProjectName(name: string) {
  const suffix = " (copy)";
  const suffixLength = suffix.length;

  if (name.length + suffixLength <= MAX_PROJECT_NAME_LENGTH) {
    return name + suffix;
  } else {
    const maxNameLength = MAX_PROJECT_NAME_LENGTH - suffixLength - 3;
    const trimmedName = name.slice(0, maxNameLength);
    return trimmedName + "..." + suffix;
  }
}

const getDefaultData = ({ projectName = "", projectDescription = "" } = {}) => ({
  projectName: projectName && addCopySuffixToProjectName(projectName),
  projectDescription,
});

export const DuplicateProjectPanel = () => {
  const t = useTranslate();
  const params = new URLSearchParams(useLocation().search);
  const projectId = useParams<"projectId">().projectId! || params.get("id")!;

  const queryClient = useQueryClient();
  const formProps = useForm<DuplicateProjectFormSchema>({
    defaultValues: getDefaultData(
      queryClient.getQueryData(queryKeys[appShieldingRootPath].projects().item(projectId).details()),
    ),
    resolver: zodResolver(DuplicateProjectFormSchema),
  });

  const { isLoading, isError, data: project } = useGetProject(projectId);

  useEffect(() => {
    formProps.reset(getDefaultData(project));
  }, [formProps, project]);

  const selectShielderOptions = project
    ? [
        {
          label: (
            <ShielderLabel
              platform={project.shielderPlatform}
              version={project.shielderVersion}
              status={project.shielderStatus}
            />
          ),
          value: project.shielderId,
        },
      ]
    : [];

  const { mutateAsync: duplicateProjectAsync, error: mutationError } = useDuplicateProject(projectId, project);

  const onSubmit = useCallback<OnSubmitCallback<DuplicateProjectFormSchema>>(
    input => {
      addAction({
        name: "duplicate-project",
        product: ActionProducts.AppShielding,
        feature: AppShieldingFeatures.DuplicateProject,
      });

      return duplicateProjectAsync({
        projectName: input.projectName,
        projectDescription: input.projectDescription ?? "",
      });
    },
    [duplicateProjectAsync],
  );

  return (
    <SideForm
      formProps={formProps}
      isLoading={isLoading || isError}
      mutationErrors={mutationError}
      onSubmit={onSubmit}
      submitButtonProps={{ startIcon: <ContentCopyIcon /> }}
      cancelNavigationPath=".."
      successNavigationPath=".."
      translationComponentName="duplicate-project"
    >
      <Box mb={2.5}>
        <FormSelect
          isLoading={isLoading || isError}
          skeletonProps={useMemo(() => ({ width: "79px" }), [])}
          label={t("component.new-project.formSelect.platform.label")}
          nothingSelectedLabel={t("component.new-project.formSelect.platform.nothing-selected-label")}
          options={selectShielderOptions}
          id="shield-select"
          fullWidth
          helperText={<>{t("component.new-project.formSelect.platform.helper-text.secondary")}</>}
          name="shielderId"
          value={project?.shielderId ?? ""}
          disabled
        />
      </Box>
      <EditProjectSummaryFormContent loading={isLoading || isError} />
    </SideForm>
  );
};
