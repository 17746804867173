import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ActionProducts, addAction, AppShieldingFeatures } from "../../../../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { appShieldingRootPath } from "../../../../../app-shielding-root-path";
import { ConfigStatus } from "../../../../configurations/api/types/config-status";
import { ShielderStatus } from "../../../../shielders/types/shielder-status";
import { UnsupportedShielderVersionAlert } from "../../../../shielders/ui/unsupported-shielder-version-alert";
import { ShieldingsList } from "../../../../shieldings/features/shieldings-list";
import { GetProject } from "../../../api/get-project";

interface ProjectDetailsContentProps {
  project: GetProject | undefined;
}

export function ProjectDetailsContent({ project }: ProjectDetailsContentProps) {
  const t = useTranslate();
  const { classes } = useStyles();

  const hasConfig = project?.configStatus !== ConfigStatus.None;
  const isUnsupportedShielder = project?.shielderStatus === ShielderStatus.Unsupported;

  return (
    <Box data-testid="Configurations" className={classes.root}>
      {isUnsupportedShielder && (
        <UnsupportedShielderVersionAlert
          project={project}
          description={
            hasConfig
              ? t("common.shielder-status.alert.unsupported-shield-version.with-configuration.description")
              : t("common.shielder-status.alert.unsupported-shield-version.no-configuration.description")
          }
          selectVersionPath={`/${appShieldingRootPath}/projects/${project.id}/select-version`}
          onChange={() => {
            addAction({
              name: "open-change-shielder",
              product: ActionProducts.AppShielding,
              feature: AppShieldingFeatures.ProjectsDetails,
            });
          }}
        />
      )}
      <ShieldingsList project={project} />
    </Box>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(1.125),
    width: "100%",
    gap: theme.spacing(2.5),
    flexGrow: 1,
  },
}));
