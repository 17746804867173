import { MoreVert as MoreVertIcon, SvgIconComponent } from "@mui/icons-material";
import { Button, IconButton, ListItemIcon, Menu, MenuItem, MenuProps } from "@mui/material";
import { KeyboardEvent, MouseEvent, useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";

interface ContextMenuOption {
  value: string;
  label: string;
  icon?: SvgIconComponent;
  // For the moment we have either red or normal color so keep boolean type.
  // If there will be more colors in the future we have to switch to more general property: color.
  // What is not clear for the moment is whether it should be a standard color field (with hex encoded color),
  // or reference to MUI palette, like "error.main", or just a enum with limited list of colors.
  // Therefore, for the moment just stick with easiest boolean implementation
  red?: boolean;
  onClick?: (e: MouseEvent<HTMLLIElement>) => void;
}

export interface ContextMenuProps {
  options: ContextMenuOption[];
  outlined?: boolean;
  "data-testid"?: string;
  "aria-label"?: string;
  PaperProps?: Omit<MenuProps["PaperProps"], "elevation" | "variant" | "sx" | "component">;
}

export function ContextMenu({
  options,
  outlined,
  ["data-testid"]: dataTestId,
  ["aria-label"]: ariaLabel,
  PaperProps,
}: ContextMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const btnRef = useRef(null);
  const contextButtonProps = {
    ref: btnRef,
    ["aria-haspopup"]: true,
    ["aria-expanded"]: isOpen,
    ["aria-label"]: ariaLabel,
    ["data-testid"]: dataTestId,
    onClick: () => setIsOpen(open => !open),
    onKeyDown: (event: KeyboardEvent<HTMLButtonElement>) => event.stopPropagation(),
  } as const;

  const { classes, cx } = useStyles();

  const contextButton = outlined ? (
    <Button {...contextButtonProps} className={classes.contextButton} color="primary" variant="outlined" disableRipple>
      <MoreVertIcon />
    </Button>
  ) : (
    <IconButton className={classes.iconButton} disableRipple {...contextButtonProps}>
      <MoreVertIcon />
    </IconButton>
  );

  return (
    <>
      {contextButton}
      <Menu
        anchorEl={btnRef.current}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={isOpen}
        disableAutoFocusItem={true}
        PaperProps={
          {
            ...PaperProps,
            elevation: 0,
            variant: "outlined",
            className: classes.paper,
            component: "section",
          } as MenuProps["PaperProps"] // Because of https://github.com/mui/material-ui/issues/27703
        }
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        onClose={close}
      >
        {options.map(item => {
          return (
            <MenuItem
              key={item.value.toString()}
              onClick={e => {
                close();
                item.onClick?.(e);
              }}
              className={cx(item.red && classes.redItem)}
            >
              {item.icon && <ListItemIcon data-testid="list-item-icon">{<item.icon />}</ListItemIcon>}
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

const useStyles = makeStyles()(theme => ({
  contextButton: {
    paddingLeft: 0,
    paddingRight: 0,
    "&.MuiButtonBase-root": {
      minWidth: theme.spacing(5.5),
    },
  },
  iconButton: {
    // This makes the clickable area big enough so the users don't click outside by mistake
    overflow: "visible",
    "&::after": {
      content: '""',
      width: theme.spacing(9),
      height: theme.spacing(9),
      position: "absolute",
    },
  },
  redItem: {
    color: theme.palette.error.main,
    "& .MuiSvgIcon-root": {
      color: theme.palette.error.main,
    },
  },
  paper: {
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    padding: theme.spacing(1, 0),
    minWidth: 240,
  },
}));
