import { ConfigurationItem as ConfigurationItemType, DataType, Widget } from "@mob/shielder-metadata";
import { z } from "zod";

import { omit } from "../../../../../../_common/utils/omit";
import { ConfigValues } from "../../api/types/config-values";
import { conditionalParameterValidationMessage } from "../utils/conditional-parameter-validation-message";
import { TYPES } from "../utils/data-type-zod-types";
import {
  conditionallyAllowedValues,
  eachLineMaxLength,
  eachLineRegExp,
  isAnyConditionValid,
  maxLength,
  maxLinesCount,
  maxValue,
  minValue,
  regExp,
  required,
  urlProtocol,
  validate,
} from "../utils/validators";

const fmtFormNumber = (form: ConfigValues, name: string) => {
  const value = form[name];
  return typeof value === "string" ? { ...form, [name]: value === "" ? null : +value } : form;
};

export type ConfigurationFormSchema = ReturnType<typeof generateConfigurationFormSchema>;
// eslint-disable-next-line sonarjs/cognitive-complexity
export function generateConfigurationFormSchema(items: ConfigurationItemType[]) {
  let schema: z.ZodTypeAny = z.object({
    debugMode: z.boolean(),
    ...Object.fromEntries(
      items.flatMap(item => {
        let schema = TYPES[item.data.type];

        if ([DataType.String, DataType.Integer].includes(item.data.type)) {
          schema = schema.transform((value: string | number) => (typeof value === "string" ? value.trim() : value));
        }

        if (item.invisible) {
          schema = schema.optional();
        }

        return [[item.name, schema]];
      }),
    ),
  });

  /**
   * It is necessary to generate these transformations for each input in order to validate
   * which ones should be present in the form and which ones should not,
   * this specific transformations should be added before any validation (".refine" or ".superRefine"), since if any of these fails
   * the transformation of the subsequent inputs will not be executed, which can generate inconsistencies in the number of errors.
   * We need to iterate through the all items to generate the transformations only,
   * and then iterate again to generate the validations only on visible items.
   */
  for (const item of items) {
    const { name, invisible } = item;
    if (invisible) {
      schema = schema.transform((form: ConfigValues, { addIssue }) => {
        if (isAnyConditionValid(form, invisible)) {
          return omit(form, name);
        } else if (form[name] !== undefined) {
          return form;
        } else {
          // This should never happen since widget UI logic should be in accord with transformation logic
          // but just for in case
          addIssue({
            code: z.ZodIssueCode.custom,
            message: conditionalParameterValidationMessage(name, true),
            path: [name],
          });
          return z.NEVER;
        }
      });
    }
  }

  for (const item of items) {
    const { name, widget, debugModeOnly, data, invisible } = item;

    if (debugModeOnly) {
      schema = schema.refine((form: ConfigValues) => form.debugMode || form[name] === item.data.defaultValue, {
        message: `${item.name} should be equal to "${item.data.defaultValue}" when not in Debug Mode`,
        path: [name],
      });
    }

    switch (widget) {
      case Widget.Checkbox:
        if (item.validations) {
          schema = validate(schema, name, conditionallyAllowedValues(item.validations.allowedValue));
        }
        break;

      case Widget.Input:
        if (item.validations) {
          schema = validate(
            schema,
            name,
            required(item.validations.required, item.label),
            maxLength(item.validations.maxLength),
            regExp(item.validations.regExp),
          );
        }
        break;

      case Widget.MultilineInput:
        if (item.validations) {
          schema = validate(
            schema,
            name,
            required(item.validations.required, item.label),
            maxLength(item.validations.maxLength),
            regExp(item.validations.regExp),
            maxLinesCount(item.validations.maxLinesCount),
            eachLineMaxLength(item.validations.eachLine?.maxLength),
            eachLineRegExp(item.validations.eachLine?.regExp),
          );
        }
        break;

      case Widget.NumberInput:
        schema = validate(
          schema,
          name,
          required(item.validations?.required, item.label),
          regExp({ value: "^\\d+$", message: "Is not a number" }),
          minValue(item.validations?.minValue),
          maxValue(item.validations?.maxValue),
        );
        schema = schema.transform((form: ConfigValues) => fmtFormNumber(form, name));
        break;

      case Widget.Url:
        schema = validate(
          schema,
          name,
          required(item.validations?.required, item.label),
          urlProtocol(item.httpAllowed),
        );
        break;

      case Widget.Dropdown:
      case Widget.RadioGroup:
        if (item.data.validations) {
          schema = validate(
            schema,
            name,
            conditionallyAllowedValues<boolean | string | number>(item.data.validations.allowedValue),
          );
        }

        // eslint-disable-next-line sonarjs/no-nested-switch
        switch (data.type) {
          case DataType.Boolean:
            schema = schema.transform((form: ConfigValues) => ({
              ...form,
              [name]: form[name] === true || form[name] === "true",
            }));
            break;

          case DataType.Integer:
            schema = schema.transform((form: ConfigValues) => fmtFormNumber(form, name));
            break;
        }
        break;

      case Widget.CertificateUploader:
        if (item.validations) {
          schema = validate(schema, name, required(item.validations.required, "Certificate file"));
        }
        if (invisible) {
          // If certificate field invisible, it means it should not be present, therefore it should be deleted if it is present
          schema = schema.transform((form: ConfigValues) =>
            isAnyConditionValid(form, invisible) ? { ...form, [`${name}Delete`]: true } : form,
          );
        }
        break;

      case Widget.ColorInput:
        schema = validate(
          schema,
          name,
          required(item.validations?.required, item.label),
          regExp({ value: "^([0-9a-fA-F]{3}){1,2}$", message: "Is not a hex color code" }),
        ).transform((form: ConfigValues) =>
          typeof form[name] === "string" ? { ...form, [name]: form[name].toUpperCase() } : form,
        );
        break;
    }
  }

  return schema;
}
