import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";

interface HelpButtonProps {
  itemLabel: string;
  "aria-expanded"?: boolean;
  "aria-controls"?: string;
  onClick: () => void;
}

export function HelpButton({ onClick, itemLabel, ...props }: HelpButtonProps) {
  const t = useTranslate();
  const { classes } = useStyles();
  return (
    <Box display="flex" alignItems="center" height="12px">
      <IconButton
        className={classes.helpButton}
        onClick={onClick}
        data-testid="item-help-button"
        aria-label={t("component.configuration-item.help-button.aria-label", { name: itemLabel })}
        {...props}
      >
        <HelpOutlineIcon />
      </IconButton>
    </Box>
  );
}

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    height: theme.spacing(0.75),
  },
  helpButton: {
    zIndex: 1,
    marginLeft: theme.spacing(0.5),
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem !important",
    },
  },
}));
