import { datadogRum } from "@datadog/browser-rum";

export enum ActionProducts {
  All = "all",
  AppShielding = "app-shielding",
  OnPremShielders = "app-shielding-tool",
  Settings = "settings",
}

export enum AppShieldingFeatures {
  ProjectList = "projects-list",
  ProjectsDetails = "project-details",
  CreateProject = "create-project",
  EditProject = "edit-project",
  DuplicateProject = "duplicate-project",
  DeleteProject = "delete-project",
  Configurations = "configurations",
  ChangeShielder = "change-shielder",
  ShieldingsList = "shieldings-list",
  ShieldingsDetails = "shielding-details",
}

export enum OnPremShielderFeatures {
  ShieldersList = "app-shieldings-list",
}

export enum SettingsFeatures {
  ClientCredentials = "client-credentials",
}

export enum GeneralFeatures {
  SideBar = "sidebar",
}

export function addAction({
  name,
  product,
  feature,
  context,
}: {
  name: string;
  product: ActionProducts;
  feature: AppShieldingFeatures | OnPremShielderFeatures | SettingsFeatures | GeneralFeatures;
  context?: object;
}) {
  datadogRum.addAction(name, {
    product,
    feature,
    ...context,
  });
}
