import {
  ArrowCircleRight as ArrowCircleRightIcon,
  Check as CheckIcon,
  KeyboardReturn as KeyboardReturnIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";

import { isValidationErrors } from "../../../../_common/api/errors/mutation-error";
import { queryKeys } from "../../../../_common/api/query-keys/query-keys";
import { ActionProducts, addAction, AppShieldingFeatures } from "../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../_common/i18n/hooks/use-translate.hook";
import { ID } from "../../../../_common/types";
import { SelectList } from "../../../../_common/ui/select-list/select-list";
import { OnSubmitCallback, SideForm } from "../../../../_common/ui/side-form/side-form";
import { appShieldingRootPath } from "../../app-shielding-root-path";
import { ConfigStatus } from "../configurations/api/types/config-status";
import { useGetProject } from "../projects/api/get-project";
import { useUpdateShieldVersion } from "../projects/api/update-shield-version/use-update-shield-version";
import { useGetShieldersList } from "../shielders/api/get-shielders-list-item";
import { ShielderStatus } from "../shielders/types/shielder-status";
import { ShielderLabel } from "../shielders/ui/shielder-label";

interface SelectShielderForm {
  shielderId: ID;
}

const SHIELDER_INPUT_NAME = "shielderId";
const getDefaultData = ({ shielderId = "" } = {}) => ({ shielderId });

export function ChangeShieldVersionPanel() {
  const t = useTranslate();
  const projectId = useParams<"projectId">().projectId!;
  const queryClient = useQueryClient();

  const formProps = useForm<SelectShielderForm>({
    defaultValues: getDefaultData(
      queryClient.getQueryData(queryKeys[appShieldingRootPath].projects().item(projectId).details()),
    ),
  });
  const selectedShielderId = formProps.watch(SHIELDER_INPUT_NAME);

  const { isLoading: isLoadingProject, isError: isProjectError, data: project } = useGetProject(projectId);

  useEffect(() => {
    formProps.reset(getDefaultData(project));
  }, [formProps, project]);

  const {
    mutateAsync: updateShieldVersionAsync,
    isError: isMutationError,
    error: mutationError,
  } = useUpdateShieldVersion(projectId, project);

  const {
    isLoading: isLoadingShielders,
    isError: isShielderError,
    data: shielders = [],
  } = useGetShieldersList(ShielderStatus.Latest, ShielderStatus.Outdated);

  const hasProjectConfiguration = project && project.configStatus !== ConfigStatus.None;

  const handleSubmit = useCallback<OnSubmitCallback<SelectShielderForm, SelectShielderForm>>(
    form => {
      if (hasProjectConfiguration) return form;

      addAction({
        name: "change-shielder",
        product: ActionProducts.AppShielding,
        feature: AppShieldingFeatures.ChangeShielder,
      });

      const shielderSelected = shielders.find(shielder => shielder.shielderId === form.shielderId)!;
      return updateShieldVersionAsync({
        shielderId: shielderSelected.shielderId,
        shielderVersion: shielderSelected.shielderVersion,
        shielderVersionStatus: shielderSelected.shielderStatus,
        projectUpdatedAt: project!.projectUpdatedAt,
      }).then(() => form);
    },
    [project, hasProjectConfiguration, shielders, updateShieldVersionAsync],
  );

  const updateConfigurationNavigationPath = useCallback(
    ({ shielderId }: SelectShielderForm) =>
      `/${appShieldingRootPath}/projects/${projectId}/change-version?new-version=${shielderId}`,
    [projectId],
  );

  const items = useMemo(
    () =>
      shielders
        .filter(shielder => shielder.shielderPlatform === project?.shielderPlatform)
        .map(shielder => ({
          value: shielder.shielderId,
          label: (
            <ShielderLabel
              platform={shielder.shielderPlatform}
              version={shielder.shielderVersion}
              status={shielder.shielderStatus}
              hidePlatformName
            />
          ),
        })),
    [project?.shielderPlatform, shielders],
  );

  // It is not allowed to change shielder version when project has API key
  if (project?.apiKeyId) {
    return <Navigate replace to=".." />;
  }

  const isLoading = isLoadingShielders || isLoadingProject || isProjectError || isShielderError;

  return (
    <SideForm
      formProps={formProps}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      mutationErrors={mutationError}
      translationComponentName="shielder-list"
      submitButtonProps={{
        label: hasProjectConfiguration ? t("component.shielder-list.form.submit") : t("word.save"),
        disabled: selectedShielderId === project?.shielderId || (isMutationError && !isValidationErrors(mutationError)),
        startIcon: hasProjectConfiguration ? <ArrowCircleRightIcon /> : <SaveIcon />,
      }}
      cancelNavigationPath=".."
      successNavigationPath={hasProjectConfiguration ? updateConfigurationNavigationPath : ".."}
      showAlertAlways
      alertType="warning"
    >
      <SelectList
        isLoading={isLoading}
        items={items}
        name={SHIELDER_INPUT_NAME}
        selectedItemAddon={
          <Typography>
            {t("word.selected")}
            <CheckIcon fontSize="small" />
          </Typography>
        }
        unselectedItemAddon={
          <Typography>
            {t("word.select")}
            <KeyboardReturnIcon fontSize="small" />
          </Typography>
        }
      />
    </SideForm>
  );
}
