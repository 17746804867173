import { useCallback, useState } from "react";

import { ConfirmationDialog, ConfirmationDialogProps } from "./confirmation-dialog";

type AsyncConfirmationDialogProps<T> = Omit<ConfirmationDialogProps<T>, "onConfirm"> & {
  onConfirm?: () => Promise<T | void>;
};

export function AsyncConfirmationDialog<T>({
  children,
  onConfirm,
  ...confirmationDialogProps
}: AsyncConfirmationDialogProps<T>) {
  const [submitting, setSubmitting] = useState(false);

  const clickConfirmHandler = useCallback(() => {
    setSubmitting(true);
    onConfirm!()
      .catch(() => {})
      .finally(() => setSubmitting(false));
  }, [onConfirm]);

  return (
    <ConfirmationDialog
      {...confirmationDialogProps}
      onConfirm={onConfirm ? clickConfirmHandler : undefined}
      submitting={submitting}
    >
      {children}
    </ConfirmationDialog>
  );
}
