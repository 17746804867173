import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ActionProducts, addAction, AppShieldingFeatures } from "../../../../../../_common/datadog/add-action";
import { useTranslate } from "../../../../../../_common/i18n/hooks/use-translate.hook";
import { ID } from "../../../../../../_common/types";
import { AsyncConfirmationDialog } from "../../../../../../_common/ui/confirmation-dialog/async-confirmation-dialog";
import { useDeleteProject } from "../../api/delete-project";

interface DeleteProjectDialogProps {
  projectId?: ID;
  projectName?: string;
  onClose: () => void;
  onSuccess?: () => void;
}

export function DeleteProjectDialog({ projectId, projectName, onClose, onSuccess }: DeleteProjectDialogProps) {
  const t = useTranslate();
  const { mutateAsync } = useDeleteProject(projectId, { onSuccess });
  const { classes } = useStyles();

  return (
    <AsyncConfirmationDialog
      onConfirm={() => {
        addAction({
          name: "delete-project",
          product: ActionProducts.AppShielding,
          feature: AppShieldingFeatures.DeleteProject,
        });

        return mutateAsync(projectId!).then(onClose);
      }}
      reverseButtonOrder
      confirmButtonProps={{ startIcon: <DeleteIcon /> }}
      onCancel={onClose}
      title={t("component.delete-project-dialog.title")}
      confirmButtonLabel={t("word.delete")}
      open={projectId !== undefined}
      type="error"
    >
      <Box width={440}>
        <Typography color="textPrimary" className={classes.projectName}>
          {t("component.delete-project-dialog.description1", {
            name: projectName ?? "",
          })}
        </Typography>

        <br />
        <Typography color="textPrimary">{t("component.delete-project-dialog.description2")}</Typography>
      </Box>
    </AsyncConfirmationDialog>
  );
}

const useStyles = makeStyles()(() => ({
  projectName: {
    wordWrap: "break-word",
  },
}));
